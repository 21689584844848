
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { ScheduledReportFormObjectModel } from '@/types/edit-bot.types';
import { ScheduledReport } from '@/types/om27.types';
import { padLeadingZero } from '@/utils/number';

@Component({
  components: {
    ValidatedForm,
    ValidationObserver,
  },
})
export default class CreateScheduledReportForm extends Vue {
  @Prop({ type: Boolean }) loading!: boolean;
  @Prop({ type: String }) mode!: string;
  @Prop({ type: Object }) report!: ScheduledReport | null;

  formModel!: ScheduledReportFormObjectModel;

  get form() {
    return orderBy(values(this.formModel), 'sequence').map(f => f.field);
  }

  set form(v: { key: keyof ScheduledReportFormObjectModel; value: any; }[]) {
    for (const { key, value } of v) {
      this.$set(this.formModel[key].field, 'value', value);
    }
  }

  get runDayValues() {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }

  get emailValidator(): (v: string) => Promise<string> {
    return (v: string): Promise<string> => {
      let valid = true;
      if (v) {
        const IPRegex = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
        const IPs = v.split(',');
        if (IPs.some(ip => !ip.match(IPRegex))) {
          valid = false;
        }
        return Promise.resolve(valid ? '' : 'Please enter valid email addresses');
      }
      return Promise.resolve('');
    };
  }

  extractRundaysFromValue(selectedDays: string[]): any {
    return this.runDayValues.reduce((acc: any, value: string, i: number) => {
      acc[value.toLowerCase()] = selectedDays.includes(value) ? i : null;
      return acc;
    }, {});
  }

  extractValueFromRundays(value: any): string[] {
    if (!value) {
      return [];
    }
    return this.runDayValues.map((dayAbbrv) => {
      const key = dayAbbrv.toLocaleLowerCase();
      return value[key] !== null ? dayAbbrv : null;
    }).filter((dayAbbrv) => dayAbbrv) as string[];
  }

  prepareFormData(formData: ScheduledReportFormObjectModel): ScheduledReport {
    const form: ScheduledReport = this.extractRundaysFromValue(formData.runDays.field.value || []);
    form.reportName = formData.reportName.field.value;
    form.sqlQuery = formData.sqlQuery.field.value;
    form.parameterized = formData.parameterized.field.value;
    form.disabled = formData.disabled.field.value;
    form.toAddressees = formData.toAddressees.field.value;
    form.ccAddressees = formData.ccAddressees.field.value || null;
    form.subject = formData.emailSubject.field.value;
    form.emailTemplate = formData.emailBody.field.value || null;
    const [firstRunHour, firstRunMinute] = formData.firstRunTime.field.value.split(':');
    const [secondRunHour, secondRunMinute] = formData.secondRunTime.field.value.split(':');
    form.firstRunHr = firstRunHour ? Number(firstRunHour) : 0;
    form.firstRunMin = firstRunMinute ? Number(firstRunMinute) : 0;
    form.secondRunHr = secondRunHour ? Number(secondRunHour) : null;
    form.secondRunMin = secondRunMinute ? Number(secondRunMinute) : null;
    return form;
  }

  handleForm() {
    this.$emit('submit', this.prepareFormData(this.formModel));
  }

  created() {
    const { report } = this;
    const firstRunTime = report
      ? `${padLeadingZero(report.firstRunHr, 2)}:${padLeadingZero(report.firstRunMin, 2)}`
      : '00:00';
    const secondRunTime = typeof report?.secondRunHr === 'number' && typeof report?.secondRunMin === 'number'
      ? `${padLeadingZero(report.secondRunHr, 2)}:${padLeadingZero(report.secondRunMin, 2)}`
      : '';

    this.formModel = {
      reportName: {
        sequence: 1,
        field: {
          key: 'reportName',
          type: 'text',
          label: 'Report Name',
          value: report?.reportName || '',
          validation: {
            rules: 'required',
          },
          attrs: {
            disabled: this.mode === 'edit',
          },
        },
      },
      disabled: {
        sequence: 2,
        field: {
          key: 'disabled',
          type: 'checkbox-single',
          label: 'Disabled',
          value: !!report?.disabled,
        },
      },
      sqlQuery: {
        sequence: 3,
        field: {
          key: 'sqlQuery',
          type: 'long-text',
          label: 'SQL Query',
          value: report?.sqlQuery || '',
          validation: {
            rules: 'required',
          },
          attrs: {
            rows: '5',
          }
        },
      },
      parameterized: {
        sequence: 4,
        field: {
          key: 'parameterized',
          type: 'checkbox-single',
          label: 'Parameterized',
          value: !!report?.parameterized,
        },
      },
      toAddressees: {
        sequence: 5,
        field: {
          key: 'toAddressees',
          type: 'input-chips',
          value: report?.toAddressees || '',
          label: 'To Addressees',
          leadingHeader: ' ',
          inputChipsProps: {
            unique: true,
          },
          customValidator: this.emailValidator,
          helpText: 'Enter a valid email address, press ENTER to add more',
        },
      },
      ccAddressees: {
        sequence: 5,
        field: {
          key: 'ccAddressees',
          type: 'input-chips',
          value: report?.ccAddressees || '',
          label: 'CC Addressees',
          inputChipsProps: {
            unique: true,
          },
          customValidator: this.emailValidator,
          helpText: 'Enter a valid email address, press ENTER to add more',
        },
      },
      emailSubject: {
        sequence: 6,
        field: {
          key: 'emailSubject',
          type: 'text',
          label: 'Email Subject',
          value: report?.subject || '',
        },
      },
      emailBody: {
        sequence: 7,
        field: {
          key: 'emailBody',
          type: 'long-text',
          label: 'Email Body',
          value: report?.emailTemplate || '',
          attrs: {
            rows: '5',
          },
        },
      },
      runDays: {
        sequence: 8,
        field: {
          key: 'runDays',
          type: 'checkbox',
          label: 'Run Days',
          value: this.extractValueFromRundays(report),
          options: this.runDayValues,
          attrs: {
            inline: 'true',
          },
        },
      },
      firstRunTime: {
        sequence: 9,
        field: {
          key: 'firstRunTime',
          type: 'text',
          label: 'First Run Time (HH:MM)',
          value: firstRunTime,
          validation: {
            rules: {
              required: true,
              regex: '^([0-1][0-9]|2[0-3]):[0-5][0-9]$',
            },
            customMessages: {
              regex: 'Please enter a valid time',
            },
          },
        },
      },
      secondRunTime: {
        sequence: 10,
        field: {
          key: 'secondRunTime',
          type: 'text',
          label: 'Second Run Time (HH:MM)',
          value: secondRunTime,
          validation: {
            rules: {
              regex: '^([0-1][0-9]|2[0-3]):[0-5][0-9]$',
            },
            customMessages: {
              regex: 'Please enter a valid time',
            },
          },
        },
      },

    };
  }
}
